.header {
    display: flex;
    z-index: 10;
    top: 0px;
    width: 100%;
    height: 60px;
    position: absolute;
    padding-left: 4%;
    padding-right: 4%;
    justify-content: space-between;
    font-size: medium;
    align-items: center;
    box-shadow: 2px;
    backdrop-filter: blur(3px);
  
    .logo {
        display: flex;
        align-items: center;
        gap: 20px;
        img {
            height: 35px;
            margin-right: 10px;
        }
    }
  }
  

  nav{
    position: absolute;
    z-index: 10;
    display: flex;
    right: 0%;
    width: 50%;
    height: 60px;
    align-items: center;
    justify-content: end;
    padding-right: 4%;
  
    ul {
      margin-bottom: 0px;
      display: flex;
      align-items: center;
      gap: 10px
    }
    
    a, .link{
      white-space: nowrap;
      padding: 10px;
      position: relative;
    }
  }
  
  .menu{
    background-size: 0% 2px;
    background-repeat: no-repeat;
    background-position: left bottom;
    transition: all 300ms ease;
  }
  
  .menu:hover{
    text-decoration: underline;
  }
  
@media only screen and (max-width: 1060px) {
    .header {
        position: fixed;
        background-color: none;
    }
  
    ul {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
  
    nav {
      z-index: 8;
      position: fixed;
      background-color: $black;
      justify-content: center;
      width: 100vw;
      height: 100%;
      transition: all ease 0.5s;
      left:100%;
      transform: translateX(100%);

      a, .link {
        margin: 5vh 0;
      }
    }
  
    nav.active {
      transform: translateX(-100%);
    }

    .menu{
        width: 90%;
        transition: all 300ms ease;
        text-align: center;
      }
  }
  
  @media only screen and (max-width: 590px) {
  
    .mask{
      height: 60px;
    }
  
    .header{
      top: 0px;
      width: 100%;
      height: 60px;
      padding-left: 5%;
      padding-right: 5%;
    }
  
    .header .logo {
      position: 50%;
      font-size: large;
    }
  
    .header .logo span {
      width: 20px;
      height: 20px;
    }
  
    ul {
      flex-direction: column;
      justify-content: center;
    }
  
    nav {
      z-index: 8;
      position: fixed;
      background-color: $black;
      justify-content: center;
      width: 100vw;
      height: 100%;
      transition: all ease 0.5s;
      left:100%;
      transform: translateX(100%);

      a, .link {
        margin: 5vh 0;
      }
    }
  
    nav.active {
      transform: translateX(-100%);
    }
  
    .menu{
      transition: all 300ms ease;
    }
  }