.skill_category {
    width: 30%;
}

.sep {
    width: 100%;
    height: 1px;
    background-color: $gray;
}

.skill_item {
    p {
        color: $gray;
        text-align: start;
    }

    div p {
        margin-left: auto;
        margin-bottom: 0%;
    }
}

.progressbar {
    width: 100%;
    height: 8px;
    border-radius: 10px;
    background-color: $black;

    * {
        border-radius: 10px;
    }
}

.certif {
    background-color: $black;
    border-radius: 5px;

    svg {
        height: 42px;
        width: 42px;
        margin-right: 1rem;
        margin-left: 1rem;
    }

    * {
        margin-bottom: 0px;
    }

    p {
        color: $gray;
    }

    transition: all 0.5s ease-in-out;
}

.certif:hover {
    background-color: $over;
}


.banner {
    font-size: 32px;
    overflow: hidden;

    animation: slide 20s linear infinite;
    animation-direction: alternate;
}

@keyframes slide {
    from {
    }
    to {
    }
}

@media only screen and (max-width: 550px) {
    .skill_category {
        width: 100%;
    }
}