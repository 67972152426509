.service_icon {
    font-size: 400%;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.service_card {
    background-color: $black;
    border-left: solid 5px;
    border-radius: 5px;
    padding-left: 0px !important;

    h5 {
        color: white;
    }

    p {
        text-align: start;
        color: $gray;
        margin: 0%;
    }
    transition: all 0.5s ease-in-out;
}

.service_card:hover {
    background-color: $over;
}

@media only screen and (max-width: 550px) {
    .service_icon {
        font-size: 200%;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        margin-bottom: 1rem;
        align-self: flex-start;
    }

    .service_card {
        p {
            font-size: 14px;
        }

        flex-direction: column;
        padding-left: 1.5rem !important;
    }
}