@media only screen and (max-width: 1060px) {
  .toggleb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 32px;
    height: 24px;
  }
  .togglebar {
    border-radius: 2px;
    height: 12%;
    background-color: white;
  }
  #togtop {
    top: 0;
    transform-origin: center right;
    transition: all ease 0.5s;
  }
  #togmid {
    align-self: center;
    width: 70%;
    transition: all ease 0.3s;
  }
  #togbot {
    bottom: 100%;
    transform-origin: center right;
    transition: all ease 0.5s;
  }

  .toggleb.active {
    #togtop {
      transform: rotate(-45deg);
    }
    #togmid {
      width: 0px;
    }
    #togbot {
      transform: rotate(45deg);
    }
  }
}


@media only screen and (max-width: 590px) {

  .toggleb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 26px;
    height: 20px;
  }
  .togglebar {
    border-radius: 2px;
    height: 12%;
    background-color: white;
  }
  #togtop {
    top: 0;
    transform-origin: center right;
    transition: all ease 0.5s;
  }
  #togmid {
    align-self: center;
    width: 70%;
    transition: all ease 0.3s;
  }
  #togbot {
    bottom: 100%;
    transform-origin: center right;
    transition: all ease 0.5s;
  }

  .toggleb.active {
    #togtop {
      transform: rotate(-45deg);
    }
    #togmid {
      width: 0px;
    }
    #togbot {
      transform: rotate(45deg);
    }
  }
}
