.hero {
    text-align: center;
    align-items: center;
    height: 100vh;
    justify-content: center;
    background: #111111;
    z-index: -2;
}

#heroimg {
    position: relative;
    top: 20%;
    width: 20%;
}

.herotext {
    position: relative;
    top: 40%;
}

.mainb {
    margin-top: 40px;
    text-shadow: none;
}

.icon{
    font-size: 20px;
    margin-left: 10px;
    vertical-align: middle;
}

#particles1 {
    canvas {
        width: 100% !important;
        height: 100% !important;
        pointer-events: initial;
        position: absolute !important;
        z-index: 0 !important;
        top: 0px !important;
        left: 0px !important;
    }
}

.transition-mask {
    position: absolute;
    height: 30%;
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $darker-black 110%);
    top: calc(100vh - 30%);
}

.typewriter {
    font-family: $par-font;
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 23px;
}

@media only screen and (max-width: 1060px) {

}

@media only screen and (max-width: 600px) {

}