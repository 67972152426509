.contact {
    padding-left: 20%;
    padding-right: 20%;
    height: 100vh;
}

form {
    width: 45%;

    input, textarea {
        margin-bottom: 10px;
        width: 100%;
        font-size: 14px;
        padding: 0.5rem;
        border: unset;
        background-color: $black;
        border-left-style: solid;
        border-left-width: 4px;
        border-radius: 4px;
        color: white;
        font-family: $par-font;
        transition: all 0.5s ease-in-out;
    }

    input:hover, textarea:hover, input:focus, textarea:focus{
        background-color: rgba(117, 117, 117, 0.1);
    }

    input {
        height: 5vh;
    }

    textarea {
        resize: none;
    }

    h4 {
        margin: 10px 0;
        align-self: baseline;
    }
}

.contact-icon{
    font-size: 36px;
    margin-left: 1rem;
    margin-right: 1rem;
    align-self: center;
}

.arrowstyle {
    color: white;
    margin-left: auto;
    transition: all 0.5s ease-in-out;
}

.contacts {
    width: 45%;
}

.contact_card {
    background-color: $black;
    transition: all 0.5s ease-in-out;
    border-radius: 5px;
    border-left: solid 4px;

    p {
        margin-bottom: 0px;
    }
}

.contact_card:hover {
    background-color: $over;

    .arrowstyle{
        transform: translateX(10px);
    }
}

button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    color: white;
    background-color: $black;
    margin-top: 20px;
    padding: 0.5rem;
    padding-left: 10px;
    border: none;
    border-left: solid 4px $blue;
    border-radius: 4px;
    transition: all 0.5s ease-in-out;

    .arrowstyle {
        font-size: 24px;
        justify-content: right;
        margin-left: auto;
        transition: all 0.5s ease-in-out;
    }
}

button:hover {
    background-color: rgba(117, 117, 117, 0.1);

    .arrowstyle{
        transform: translateX(5px);
    }
}

.sep2 {
    width: 2px;
    height: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: white;
}

@media only screen and (max-width: 1060px) {
    .contact {
        padding-left: 5%;
        padding-right: 5%;
        flex-direction: column;
        height: fit-content;
    }

    form {
        width: 100%;
        margin-bottom: 5vh;
    }

    .contacts {
        width: 100%;
    }

    .typewriter2 {
        font-size: 17px;
    }
}