@import url("https://fonts.googleapis.com/css2?family=Cutive+Mono&display=swap");
@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@700&display=swap');

$head-font: 'Press Start 2P', monospace;
// $head-font: 'IBM Plex Mono', monospace;
$par-font: 'Cutive Mono', monospace;

$black: #161616;
$darker-black: #080808;
$gray: #8b8b8b;
$over: #222222;

$red: #FFC5C5;
$yellow: #FEFFC5;
$green: #C9FFC5;
$blue: #C5FFF8;
$purple: #E5C5FF;

$black40: rgba(22, 18, 22, 0.4);
$black50: rgba(22, 18, 22, 0.5);
$black60: rgba(22, 18, 22, 0.6);
$black70: rgba(22, 18, 22, 0.7);
$black80: rgba(22, 18, 22, 0.8);
$black90: rgba(22, 18, 22, 0.9);

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: $darker-black;
  color: white;
}

body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: $darker-black;
}

body::-webkit-scrollbar
{
	width: 12px;
	background-color: black;
}

body::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: white;
}

.body {
  margin-left: 25%;
  margin-right: 25%;
}

.section {
  padding-top: 10vh;
  padding-bottom: 10vh;
}

h1, .h1 {
  font-family: $head-font;
  font-style: normal;
  font-weight: bold;
  font-size: 48.95px;
  line-height: 94px;
  text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
}

h2, .h2 {
  font-family: $head-font;
  font-style: normal;
  font-weight: 400;
  font-size: 26.37px;
  line-height: 66px;
  text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
}

h3, .h3 {
  font-family: $head-font;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 47px;
  text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
}

h4, .h4 {
  font-family: $par-font;
  font-style: normal;
  font-weight: 400;
  font-size: 29.7px;
  line-height: 33px;
  text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
}

h5, .h5 {
  font-family: $par-font;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 23px;
}

h6, .h6 {
  font-family: $par-font;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 19px;
}

p, .p {
  font-family: $par-font;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  text-align: justify;
  color: white;
}

.p1 {
  font-family: $par-font;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 33px;
  text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
  color: white;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.red {
  color: $red;
}

.bred {
  border-color: $red;
}

.yellow {
  color: $yellow;
}

.byellow {
  border-color: $yellow;
}

.green {
  color: $green;
}

.bgreen {
  border-color: $green;
}

.blue {
  color: $blue;
}

.bblue {
  border-color: $blue;
}

.purple {
  color: $purple;
}

.bpurple {
  border-color: $purple;
}

.bgred {
  background-color: $red;
}

.bgyellow {
  background-color: $yellow;
}

.bggreen {
  background-color: $green;
}

.bgblue {
  background-color: $blue;
}

.bgpurple {
  background-color: $purple;
}

@media only screen and (max-width: 1420px) {
  .body {
    margin-left: 15%;
    margin-right: 15%;
  }
}

@media only screen and (max-width: 1060px) {
  h1, .h1 {
    font-family: $head-font;
    font-style: normal;
    font-weight: 400;
    font-size: 59.37px;
    line-height: 66px;
    text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
}

h2, .h2 {
  font-family: $head-font;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 47px;
  text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
}

h3, .h3 {
  font-family: $head-font;
  font-style: normal;
  font-weight: 400;
  font-size: 18.7px;
  line-height: 33px;
  text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
}

h4, .h4 {
  font-family: $par-font;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 23px;
}

h5, .h5 {
  font-family: $par-font;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 19px;
}

h6, .h6 {
  font-family: $par-font;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 14px;
}

.p1 {
  font-family: $par-font;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: unset;
  text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
  color: white;
}

.body {
  margin-top: 10vh;
  margin-bottom: 10vh;
  margin-left: 5%;
  margin-right: 5%;
}

.logo {
  h5, .h5 {
    margin-bottom: 0px !important;
  }
}
}

@media only screen and (max-width: 550px) {
    h1, .h1 {
      font-family: $head-font;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 66px;
      text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
  }

  h4, .h4 {
    font-family: $par-font;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
  }

  h5, .h5 {
    font-family: $par-font;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
  }

  h6, .h6 {
    font-family: $par-font;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }

  p, .p {
    font-family: $par-font;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-justify: auto;
    color: white;
  }
}