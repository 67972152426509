footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    top: 0;
    width: 100%;
    height: 160px;
    background-color: $black;
    box-shadow: 2px;
    backdrop-filter: blur(3px);

    p {
        font-size: 14px;
        color: $gray;
    }

    .sep {
        height: 2px;
        background-color: black;
        opacity: 0.25;
        border-bottom: solid 1px $gray;
        width: min(500px, 70%);
        margin: 20px;
    }
}

.arrowup{
    font-size: 24px;
    transition: all 0.5s ease-in-out;
}

.arrowup:hover{
    transform: translateY(-5px);
}

.icon_container {
    width: 240px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    a {
        font-size: 24px;
        transition: all 0.5s ease-in-out;
    }

    a:hover {
        transform: translateY(-5px);
    }
}

@media only screen and (max-width: 1420px) {
    footer {
        height: 120px;
        p {
            height: 40px;
            font-size: 12px;
        }

        .sep {
            height: 2px;
            margin: 5px;
        }
    }

    .arrowup {
        font-size: 18px;
    }

    .icon_container {
        width: 180px;

        a {
            font-size: 18px;
        }
    }
}