#aboutme {
    color: white;
}

.resume-btn {
    display: block;
    background-color: $black;
    border-radius: 5px;
    width: fit-content;
    margin-left: auto;
    margin-top: 20px;
    padding: 0.2rem;

    transition: all 0.5s ease-in-out;

    p {
        margin-bottom: 0px;
    }
}

.resume-btn:hover {
    background-color: $over;
}