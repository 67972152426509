.project_card {
    background-color: $black;
    border-radius: 5px;
    border-left: solid 5px;
    width: 48%;

    img, iframe {
        border-radius: 10px;
        width: 100%;
    }

    transition: all 0.5s ease-in-out;

    h4 {
        color: white;
    }

    .desc {
        color: $gray;
        text-align: start;
    }

    .tags .tag span {
        border: solid 1px;
        border-radius: 10px;
        font-size: 13px;
        margin-bottom: 0.4rem;
    }
}

.taglabel {
    font-family: $par-font;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    text-align: justify;
}

.project_card:hover {
    transform: translateY(-5px);
    background-color: $over;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

@media only screen and (max-width: 550px) {

    .project_card {
        width: 100%;

        .tags .tag span {
            border: solid 1px;
            border-radius: 10px;
            font-size: 10px;
            margin-bottom: 0.4rem;
        }
    }
}